@import "../../styles/mixins";
@import "../../styles/typography";
@import "../../styles/responsive";

.threeColumnsBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 240px;
  width: 100%;
  margin: auto;

  @include only-on(mdUp) {
    max-width: 250px;
  }

  @include only-on(lgUp) {
    max-width: none;
    width: auto;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }
}

.threeColumnsBlock__image {
  object-fit: contain;
  text-align: center;
  width: 150px;
  height: auto;
  flex-shrink: 0;

  @include only-on(lgUp) {
    width: 300px;
  }
}

.threeColumnsBlock__text {
  @extend .bodyText;
  text-align: center;
  margin-top: 10px;

  @include only-on(lgUp) {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
  }
}

.threeColumnsBlock__header {
  @extend .titleText;
  text-align: center;
  margin-bottom: 10px;

  @include only-on(lgUp) {
    margin-bottom: 20px;
  }
}

.threeColumnsBlock__title {
  @include only-on(lgUp) {
    flex: 1 1 100%;
  }
}

.threeColumnsBlock__link {
  @include only-on(lgUp) {
    margin-top: 10px;
    min-height: 2.5em;
  }

  @include only-on(mdDown) {
    margin-top: 10px;
  }
}

.threeColumnsBlock--SmallHeader {
  .threeColumnsBlock__text {
    margin-left: auto;
    margin-right: auto;
    max-width: 280px;

    @include only-on(lg) {
      max-width: 22vw;
    }

    @include only-on(mdDown) {
      max-width: 250px;
      margin-top: 20px;
    }
  }
}

.threeColumnsBlock__col {
  text-align: center;
  width: 100%;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  margin-left: 10px;
  margin-right: 10px;

  // @include only-on(mdDown) {
  //   margin-bottom: 80px;
  // }
  @include only-on(mdDown) {
    &:not(:last-child) {
      margin-bottom: 80px;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}

.threeColumnsBlock--LargeHeader {
  @include only-on(lgUp) {
    margin-left: -20px;
    margin-right: -20px;
  }

  .threeColumnsBlock__col {
    text-align: left;
    padding: 0px 20px;
    @include only-on(mdDown) {
      display: flex;
      align-items: flex-start;
      margin-bottom: 40px;
    }
    // margin: 0px -20px;
  }

  .threeColumnsBlock__text {
    @extend .headerTextSmall;
    text-align: left;

    @include only-on(lg) {
      font-size: 23px;
      height: 145px;
    }

    @include only-on(xl) {
      font-size: 30px;
      height: 180px;
    }

    @include only-on(mdDown) {
      flex: 1 1 100%;
      font-size: 14px;
      // padding-left: 15px;
    }
  }

  .threeColumnsBlock__image {
    max-width: none;
    display: inline-block;
    width: 100%;

    @include only-on(mdDown) {
      flex: 1 1 100%;
      font-size: 14px;
    }
  }
}

.threeColumnsBlock__col--clickable {
  .threeColumnsBlock__image,
  .threeColumnsBlock__text {
    cursor: pointer;
  }
}
